@import url("https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@600;700;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
main {
  overflow-x: hidden;
}
h2,
h3,
h4,
h5 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h1 {
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
h2 {
  font-size: 40px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 14px;
}
p {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.main_container2 {
  @apply max-w-[1700px] mx-auto px-5 xl:px-7;
}
.main_container1 {
  @apply max-w-[1400px] mx-auto px-5 xl:px-7;
}
.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.nav_item {
  @apply font-Montserrat font-normal xl:mr-6 mr-4  relative flex text-center capitalize;
}
.dropdown-content .active {
  @apply text-troo-primary bg-troo-secondary;
}
.nav_link {
  @apply flex items-center h-full  text-sm xl:text-base relative before:content-[''] before:w-full before:h-[2px] before:absolute before:bottom-0 before:bg-troo-primary before:scale-x-0 before:transition-all before:ease-in-out before:duration-500 ;
}
.nav_link.active {
  @apply text-troo-primary before:scale-x-100 transition-all ease-in-out duration-500;
}

.btn1 {
  @apply py-2 px-5 rounded 
    inline-flex justify-center items-center font-bold textarea-sm gap-3 capitalize transition-all ease-in-out duration-500;
}
.btn1:hover {
  @apply transition-all ease-in-out duration-500;
}

.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > *:not(ul):not(details):not(.menu-title)
  ):not(.active):hover,
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > details
      > summary:not(.menu-title)
  ):not(.active):hover {
  background-color: #0250ac15;
}
.menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),
.menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: inherit;
  padding: 15px 15px;
}
.collapse-plus .collapse-title:after {
  right: 0.5rem;
}

.textShadow {
  text-shadow: 1px 2px 0 #fff, 0px 0px 0 #fff, 0px 0px 0 #fff, -1px 1px 0 #fff,
    1px 1px 0 #fff;
  color: #fd8f14;
}
.video .play{
  transform: translate(-50%, -50%) rotate(0deg);
}
.play {
  color: #fff;
  -webkit-animation: ripple 1s linear infinite;
  animation: ripple 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-radius: 50%;
  background-color: #fd8f14;
  padding: 15px;
}
@keyframes ripple {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(253, 143, 20, 0.2),
      0 0 0 5px rgba(253, 143, 20, 0.2), 0 0 0 20px rgba(253, 143, 20, 0.2),
      0 0 0 35px rgba(253, 143, 20, 0.2);
    box-shadow: 0 0 0 0 rgba(253, 143, 20, 0.2),
      0 0 0 5px rgba(253, 143, 20, 0.2), 0 0 0 20px rgba(253, 143, 20, 0.2),
      0 0 0 35px rgba(253, 143, 20, 0.2);
  }
  100% {
    -webkit-box-shadow: 0 0 0 5px rgba(253, 143, 20, 0.2),
      0 0 0 20px rgba(253, 143, 20, 0.2), 0 0 0 35px rgba(253, 143, 20, 0.2),
      0 0 0 45px rgba(253, 143, 20, 0.2);
    box-shadow: 0 0 0 5px rgba(253, 143, 20, 0.2),
      0 0 0 20px rgba(253, 143, 20, 0.2), 0 0 0 35px rgba(253, 143, 20, 0.2),
      0 0 0 45px rgba(253, 143, 20, 0.2);
  }
}



@media (max-width:1024px){
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .play{
    position: relative;
  }
  .video .play{
    position : absolute;
  }

}
@media (max-width:768px){
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 16px;   
  }
  h5 {
    font-size: 14px;
    
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }

}